import React from 'react'
import { Box } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import Header from '~components/casestudy/common/header'
import BackgroundImage from '~components/casestudy/centerline-cs/background-image'
import Product from '~components/casestudy/centerline-cs/product'
import Content from '~components/casestudy/common/content'

import SEOImage from '~images/socials/cl-social.jpg'
import CenterlineContentImage from '~images/backgrounds/cl-cs-impact.jpg'

const Centerline = () => {
    return (
        <>
            <SEO
                title="Centerline"
                description="Centerline is a web application that helps to facilitate and automate construction contracts. Using the portal, bidding, submittal, and all other aspects of construction management are streamlined to help you save time and money."
                image={SEOImage}
                article
            />
            <Box>
                <Header
                    BgImage={BackgroundImage}
                    name="Centerline"
                    descTitle="Constructing Something Special"
                    desc="Construction management is a incessantly growing industry with thousands
                of nuances that make it extremely difficult to manage.  Centerline hopes to streamline
                that process with a web app that integrates every aspect of it."
                />
                <Product />
                <Content
                    image={CenterlineContentImage}
                    tagline="Building the Future"
                    summary="Centerline brings every part of construction into one web app: from bidding,
                to design, to field reports and budgeting.  With a modern tool, Centerline's customers
                are now able to more efficiently deliver their projects on time and on budget, without
                the hassle of hiring more people to manage their operations.  The construction industry
                grows every year and has shown no signs of slowing down.  That makes usable and 
                comprehensive software more valuable than ever in this space.  Breach was hired to 
                complete userflow and frontend tasks, as well as assist in some backend additions.  
                Our goal was to create robust and clean interfaces that would be trivial to update in
                the future as the industry changes."
                    impact={[
                        {
                            header: 'UI/UX',
                            body: `One of the main objectives of Centerline is to allow for easy use by any party.
                        This meant that UI/UX was of the highest importance for this project.  Our team helped
                        by recreating some of the userflows to be simpler and more aesthetic so that anyone
                        would have an easy time navigating and utilizing all of the features present in the web app.`,
                        },
                        {
                            header: 'Development',
                            body: `Centerline is a bold piece of software.  It successfully integrated an enormous
                        number of processes and documents that are key to construction management.  This 
                        means that there is an immense number of features in the project.  Our team assisted
                        in implementing several of these key features, such as a review process for specification
                        documents and the creating and updating of documents of all kinds.`,
                        },
                        {
                            header: 'Quality',
                            body: `Due to the size of the Centerline codebase, it would have been impossible to not
                        introduce a single bug.  Breach helped the Centerline team find and squash bugs all
                        across the app.  Our team was also responsible for doing QA throughout the project,
                        ensuring that no feature went without thorough testing.  We also helped implement
                        automated testing throughout the app in order to catch new bugs that would arise
                        during future development.`,
                        },
                    ]}
                    highlightColor="#aec3ea"
                />
            </Box>
        </>
    )
}

export default Centerline
